import React from "react";
import "./infoSectionComputer.css";
const InfoSection = (props) => {
  const isDark = props.type === "1";
  console.log(props.type);

  return (
    <>
      <div
        className={
          isDark
            ? "InfoSectionContainer plain"
            : "InfoSectionContainer contrast"
        }
      >
        <h1
          className={
            isDark ? "InfoSectionTitle plain" : "InfoSectionTitle contrast"
          }
        >
          {props.title}
        </h1>
        {/*Este tiene que estar invertido para cuadrar*/}
        <hr
          className={isDark ? "InfoSectionHr constrast" : "InfoSectionHr plain"}
        />
        <div className="InfoSectionContent">{props.children}</div>
      </div>
    </>
  );
};

export default InfoSection;
