import React from "react";
import "./infoBubbles.css";

const InfoBubbles = (props) => {
  return (
    <>
      <div className="BubbleContainer">
        <h1>{props.title}</h1>
        <p>{props.kpi}</p>
      </div>
    </>
  );
};

export default InfoBubbles;
