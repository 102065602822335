import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

export const socialLinks = [
  {
    id: 1,
    url: "https://www.instagram.com/home_stays_mexico/",
    title: "@home_stays_mexico",
    icon: <InstagramIcon />,
  },
  {
    id: 2,
    url: "https://www.facebook.com/profile.php?id=100092353201570",
    title: "Home Stays Mexico",
    icon: <FacebookIcon />,
  },
  {
    id: 3,
    url: "https://wa.me/message/L745A3AG2ILZN1",
    title: "+5525491835",
    icon: <WhatsAppIcon />,
  },
];
