import { React } from "react";
import "./InfocardComputer.css";

const InfoCard = (props) => {
  return (
    <>
      <div className="InfoCardContainer">
        <div
          className="InfoCardPhoto"
          style={{ backgroundImage: `url(${props.url})` }}
        ></div>
        <div className="InfoCardContent">
          <h1 className="InfoCardTitle">{props.title}</h1>
          <p className="InfoCardText">{props.text}</p>
        </div>
      </div>
    </>
  );
};

export default InfoCard;
