import { React, useState } from "react";
import HomeCardSlider from "./homeCardSlider";
import HomeIcon from "@mui/icons-material/Home";
import ApartmentIcon from "@mui/icons-material/Apartment";
import "./cardComputer.css";
import "./cardSmartphone.css";
import "./cardTablet.css";
import Foto1 from "../../assets/img/BajaCalif.webp";
import Foto2 from "../../assets/img/Mountain.jpg";
import Foto3 from "../../assets/img/Playa.jpg";

const Card = () => {
  const [imagen, setImagen] = useState(false);

  const slides = [
    { url: Foto1, title: "Calif" },
    { url: Foto2, title: "Des" },
    { url: Foto3, title: "playa" },
  ];

  const changeLogo = () => {
    setImagen((prevImagen) => !prevImagen);
  };

  return (
    <>
      <div className="HomeCardContainer">
        <HomeCardSlider slides={slides} />
        <div className="HomeCardInfoSection">
          <h1 className="HomeCardTitle">Monterrey, Nuevo León</h1>
          {imagen ? (
            <HomeIcon className="HomeCardIcon" onClick={changeLogo} />
          ) : (
            <ApartmentIcon className="HomeCardIcon" onClick={changeLogo} />
          )}
          <div className="HomeCardButtonCont">
            <a className="HomeCardLink" href="www.google.com">
              Discover More
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
