import { React, useState } from "react";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import "./cardComputer.css";
import "./cardSmartphone.css";
import "./cardTablet.css";

const HomeCardSlider = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPreviouse = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <div className="sliderStyles">
      <div className="leftArrowStyles" onClick={goToPreviouse}>
        <ArrowCircleLeftIcon />
      </div>
      <div
        className="slideStyles"
        style={{ backgroundImage: `url(${slides[currentIndex].url})` }}
      ></div>
      <div className="rightArrowStyles" onClick={goToNext}>
        <ArrowCircleRightIcon />
      </div>
    </div>
  );
};

export default HomeCardSlider;
