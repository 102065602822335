import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  Home,
  LoginPage,
  RegisterPage,
  KPIs,
  Clientes,
  Propiedades,
} from "./pages/pages";
import ProtectedRoute from "./utils/protectedRouteAdmin";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="register" element={<RegisterPage />} />

          <Route path="dashboard" element={<ProtectedRoute />}>
            <Route index element={<KPIs />} />
            <Route path="KPI" element={<KPIs />} />
            <Route path="clientes" element={<Clientes />} />
            <Route path="propiedades" element={<Propiedades />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
