import React from "react";
import { NavLink } from "react-router-dom";
import "./sideBar.css";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import LogoCafe from "../../assets/img/LogoBlanco.png";

function Sidebar() {
  const navSection = {
    KPI: <WorkOutlineIcon />,
    Clientes: <PeopleAltOutlinedIcon />,
    Propiedades: <RequestQuoteIcon />,
  };

  return (
    <div className="sidebar">
      <div className="sidebarWrapper">
        <div className="sidebarMenu">
          <span className="menuLogo">
            <a href="/">
              <img className="iconLogo" alt="Logo" src={LogoCafe}></img>
            </a>
          </span>

          <ul className="sidebarList">
            <li className="sidebarListTitle"> Navegación </li>
            {Object.entries(navSection).map(([key, value]) => (
              <li className="sidebarItem ">
                <span className="space"></span>

                <NavLink to={"/dashboard/" + key} activeClassName="active">
                  {value}
                  {key}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
