import React from "react";
import {
  HomeList,
  Header,
  InfoSection,
  InfoCard,
  Footer,
} from "../components/components";
import ImgApartment from "../assets/img/Apartment.jpg";
import ImgEmptyApartment from "../assets/img/EmptyApartment.jpg";
import ImgPlaya from "../assets/img/PlayaMujer.jpg";
import LogoBlanco from "../assets/img/LogoCafe.png";

const Home = () => {
  return (
    <>
      <Header />
      <InfoSection title="CATEGORIES" type="1">
        <InfoCard
          url={ImgPlaya}
          title="HOLIDAY RENTAL"
          text="Looking for your new perfect home?"
        />
        <InfoCard
          url={ImgApartment}
          title="LONG TERM RENTAL"
          text="Looking to get away for a mont or two?"
        />
        <InfoCard
          url={ImgEmptyApartment}
          title="HOME RENTAL"
          text="Looking for your new perfect home?"
        />
      </InfoSection>

      <InfoSection title="ABOUT US" type="2">
        <div>
          <img src={LogoBlanco} alt="LogoBlanco" />
        </div>
        <div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
            aliquam nunc vitae eros eleifend, eget fringilla nulla condimentum.
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec id
            ornare justo. Morbi eu fringilla sem. Praesent tincidunt imperdiet
            sagittis. Sed vitae velit mauris. Nullam non sem eget nisi
            pellentesque tristique.
          </p>
        </div>
      </InfoSection>
      <InfoSection title="FEATURED LISTINGS" type="1">
        <HomeList />
      </InfoSection>
      <Footer />
    </>
  );
};

export default Home;
