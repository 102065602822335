import React, { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import "./protectedRoute.css";
import { Sidebar } from "../components/components";
import { requireAuth } from "../helper/verifyAuth";

const ProtectedRoute = ({ children }) => {
  const localUser = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    requireAuth();
  }, []);

  if (!localUser) {
    return <Navigate to="/login" />;
  } else {
    return (
      <>
        <div className="sharedLayout">
          <div className="containerLeft">
            <Sidebar />
          </div>
          <div className="containerRight">
            <Outlet />
          </div>
        </div>
      </>
    );
  }
};

export default ProtectedRoute;
