import React, { useEffect } from "react";
import { requireAuth } from "../../../helper/verifyAuth";

const Clientes = () => {
  const userName = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    requireAuth();
  }, []);

  if (!userName) {
    return <p>Forbidden</p>;
  } else {
    return (
      <>
        <h1>Clientes</h1>
        <h2>Vista por definir</h2>
      </>
    );
  }
};

export default Clientes;
