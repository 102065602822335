import React from "react";
import { SingInForm } from "../components/components";
import { Navigate } from "react-router-dom";

const LoginPage = () => {
  const userName = JSON.parse(localStorage.getItem("user"));
  if (userName) {
    return <Navigate to="/dashboard" />;
  } else {
    return (
      <>
        <SingInForm />
      </>
    );
  }
};

export default LoginPage;
