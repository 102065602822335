import React from "react";
import "./headerComputer.css";
import Navbar from "../NavBar/navbar";

const Header = () => {
  return (
    <>
      <div className="HeaderContainer">
        <Navbar />
        <hr className="HeaderHr" />
        <div className="FullWidthContainer">
          <div className="HeaderText">
            <h1>EXCLUSIVO. LOVELY FAMILY HOME WITH SWIMMING POOL</h1>
            <hr className="HeaderTitleHr" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
