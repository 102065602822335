import React from "react";
import "./preLoader.css";
import { GridLoader } from "react-spinners";

const PreLoader = () => {
  return (
    <>
      <div className="Preloader">
        <GridLoader color="#b0a783" size={50} />
        <h1>Loading...</h1>
      </div>
    </>
  );
};

export default PreLoader;
