import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./forms.css";
import LogoBlanco from "../../assets/img/LogoBlanco.png";
import axios from "axios";
import HomeIcon from "@mui/icons-material/Home";

const SingInForm = () => {
  const [credenciales, setCredenciales] = useState({
    EmailAddress: "",
    Password: "",
  });

  const handleChange = (e) => {
    setCredenciales({
      ...credenciales,
      [e.target.name]: e.target.value,
    });
  };
  const navigate = useNavigate();
  const path = process.env.REACT_APP_BACK_URL + "/login";

  const HandleSubmit = async (e) => {
    await axios
      .post(
        path,
        {
          EmailAddress: credenciales.EmailAddress,
          Password: credenciales.Password,
        },
        { withCredentials: true }
      )
      .then((response) => {
        console.log(response.data);
        console.log(response.data.status);
        if (response.data.status === "SUCCESS") {
          localStorage.setItem("user", JSON.stringify(response.data));
          const localUser = JSON.parse(localStorage.getItem("user"));

          if (localUser.role === 100) {
            navigate("/dashboard");
          } else {
            navigate("/post");
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="FormsBody">
        <div className="FormHome">
          <Link to="/">
            <HomeIcon className="FormHomeIcon" />
          </Link>
        </div>
        <div className="FormBox">
          <div className="FormInput">
            <h1>Log In</h1>
            <form className="Form">
              <label>Email:</label>
              <input
                className="FormText"
                type="email"
                onChange={handleChange}
                value={credenciales.EmailAddress}
                name="EmailAddress"
              />
              <label>Password:</label>
              <input
                className="FormText"
                type="Password"
                onChange={handleChange}
                value={credenciales.Password}
                name="Password"
              />
              <input
                type="button"
                className="FormButton"
                value="Sing in"
                onClick={HandleSubmit}
              />
              <p>
                Dont have have an account?{" "}
                <Link to="/register" className="FormLink">
                  Register{" "}
                </Link>{" "}
                instead
              </p>
            </form>
          </div>
          <div className="FormDecoration">
            <div>
              <img className="FormImage" src={LogoBlanco} alt="LogoBlanco" />
            </div>
            <div>
              <h1>Take a vacation so long, you forget all of your passwords</h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingInForm;
