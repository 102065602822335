import React from "react";
import Card from "./card";
import "./cardComputer.css";
import "./cardSmartphone.css";
import "./cardTablet.css";

const HomeList = () => {
  return (
    <>
      <div className="container">
        <section className="HomeListSection">
          <Card />
          <Card />
          <Card />
        </section>
      </div>
    </>
  );
};

export default HomeList;
