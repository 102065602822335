import React from "react";
import { RegisterForm } from "../components/components";

const RegisterPage = () => {
  return (
    <>
      <RegisterForm />
    </>
  );
};

export default RegisterPage;
