import React from "react";
import "./footerComputer.css";
import LogoBlanco from "../../assets/img/LogoBlanco.png";
import { socialLinks } from "../../data/sociallinks";

const Footer = () => {
  return (
    <>
      <div className="FooterContainer">
        <div className="FooterContainer1">
          {socialLinks.map((link) => {
            return (
              <>
                <div key={link.id} className="FooterC2Social">
                  <a
                    href={link.url}
                    target="_blank"
                    rel="noreferrer"
                    className="FooterIcon"
                  >
                    {link.icon}
                    {link.title}
                  </a>
                </div>
              </>
            );
          })}
        </div>
        <div className="FooterContainer2">
          <img src={LogoBlanco} alt="Logo" className="HeaderNavImg" />
        </div>
        <div className="FooterContainer3">Avisos de Privacidad</div>
      </div>
    </>
  );
};

export default Footer;
