import React from "react";

const Propiedades = () => {
  return (
    <>
      <h1>PROPIEDADES</h1>
      <h2>Vista por definir</h2>
    </>
  );
};

export default Propiedades;
