import axios from "axios";

const requireAuth = async (e) => {
  await axios
    .post(
      "http://localhost:9000/verify",
      {
        data: "someJsonData",
      },

      { withCredentials: true }
    )
    .then((response) => {
      if (!response.data.status === "SUCCESS") {
        localStorage.clear();
      }
    })
    .catch((err) => {
      localStorage.clear();
    });
};

export { requireAuth };
