import React from "react";
import Logo from "../../assets/img/LogoBlanco.png";
import { Link } from "react-router-dom";
import "./navbar.css";

const Navbar = () => {
  return (
    <>
      <nav className="HeaderNav">
        <div className="HeaderContainer1"> </div>
        <div className="HeaderContainer2">
          <img src={Logo} alt="Logo" className="HeaderNavImg" />
        </div>
        <div className="HeaderContainer3">
          <div>
            <Link to="/login" className="HeaderLink">
              <button class="HeaderButton HeaderButtonDark">Log In</button>
            </Link>
            <Link to="/register" className="HeaderLink">
              <button class="HeaderButton HeaderButtonLight">Register</button>
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
