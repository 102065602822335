import React, { useEffect, useState } from "react";
import "./stats.css";
import { InfoBubbles } from "../../../components/components";
import { Line } from "react-chartjs-2";
import { requireAuth } from "../../../helper/verifyAuth";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from "chart.js";

import { Navigate } from "react-router-dom";
import { PreLoader } from "../../../animations/animations";

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement);

const KPIs = () => {
  const userName = JSON.parse(localStorage.getItem("user"));
  const [isLoading, setIsLoading] = useState(true);
  const data = {
    labels: [
      "May 28",
      "May 29",
      "May 30",
      "May 31",
      "June 01",
      "June 02",
      "June 03",
      "June 04",
      "June 05",
    ],
    datasets: [
      {
        data: [1, 2, 3.7, 4, 4.55, 6, 7.2, 6.1, 9],
        backgroundColor: "transparent",
        borderColor: "#e4dccf",
        pointBorderColor: "#b0a783",
        pointBorderWidth: 2,
        tension: 0.5,
      },
    ],
  };

  const options = {
    plugins: {
      legend: false,
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        min: 0,
        max: 10,
      },
    },
    maintainAspectRatio: false,
    responsive: true,
  };

  useEffect(() => {
    requireAuth();
  }, []);

  if (!userName) {
    return <Navigate to="/login" />;
  } else {
    return (
      <>
        {isLoading ? (
          <PreLoader />
        ) : (
          <div className="StatContainer">
            <h1>Hello, {userName.name}</h1>
            <hr />

            <h3>Financial KPI</h3>
            <div className="kpiContanier">
              <InfoBubbles title="Monthly Income" kpi="$50,564" />
              <InfoBubbles title="Monthly Commission" kpi="$10,264" />
              <InfoBubbles title="Nights Rented" kpi="20" />
              <InfoBubbles title="Number of Properties" kpi="20" />
              <InfoBubbles title="Number of Clients Registered" kpi="23" />
            </div>
            <div className="graphContainer">
              <div className="lineContainer">
                <h3>Financial KPI</h3>
                <Line data={data} options={options}></Line>
              </div>
              <div className="lineContainer">
                <h3>Other KPI</h3>
                <Line data={data} options={options}></Line>
              </div>
              <button onClick={() => setIsLoading(false)}>ff</button>
            </div>
          </div>
        )}
      </>
    );
  }
};

export default KPIs;
